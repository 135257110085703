(function () {
  'use strict';

  class CommentZoom {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, comment, entries, callback) {
      const vm = this;
      vm.obj = comment;
      vm.obj.entries = entries;

      vm.$mdDialog.show({
        controller: 'CommentZoomCtrl',
        controllerAs: 'commentZoom',
        templateUrl: 'components/comment-zoom/comment-zoom.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:CommentZoom
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('CommentZoom', CommentZoom);
}());
